<template>
  <div
    class="modal"
    @click.self="$emit('input')"
    v-click-outside="onClickOutside"
  >
    <div class="modal__body">
      <div class="modal__close" @click="onClickOutside">
        <Icons icon-name="x" />
      </div>
      <div class="modal-content">
        <span class="modal-content__title">Обратный звонок</span>
        <span class="modal-content__subtitle"
          >Оставьте свои данные и наш менеджер перезвонит вам</span
        >
        <aside class="modal-content-bottom">
          <form @submit.prevent="submitData">
            <div class="modal-content-bottom__flex">
              <div class="modal-content-bottom__input">
                <span>Ваш телефон</span>
                <input
                  placeholder="+7 (###) ###-##-##"
                  v-mask="phoneMask"
                  v-model="phone"
                  :class="{ success: phone.length >= 18, error: isError.phone }"
                />
                <span v-if="isError.phone" class="label-error">{{
                  1 > phone.length
                    ? 'Обязательное поле для заполнения'
                    : 'Введите корректный номер телефона'
                }}</span>
              </div>
              <div class="modal-content-bottom__input">
                <span>Ваше имя</span>
                <input
                  type="text"
                  placeholder="Введите ваше имя"
                  v-model="name"
                  :class="{ success: name.length > 1, error: isError.name }"
                />
                <span v-if="isError.name" class="label-error"
                  >Обязательное поле для заполнения</span
                >
              </div>
              <RFButton
                :disabled="loading || isError.phone || isError.name"
                class="modal-content-bottom__btn"
                color="primary"
                @submit="submitData"
              >
                {{ loading ? 'Отправляется' : 'Отправить' }}
              </RFButton>
              <span v-if="isPending" class="modal-content-middle__text--green"
                >Ваша заявка отправлена</span
              >
              <span
                v-if="isPendingError"
                class="modal-content-middle__text--red"
                >Ошибка отправки</span
              >
            </div>
          </form>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icons from '@/components/atoms/Icons/index.vue'
import vClickOutside from 'v-click-outside'
import RFButton from '@/components/atoms/Buttons/index.vue'
import VueMask from 'v-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueMask, VueAxios, axios)

export default {
  components: { Icons, RFButton },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      phoneMask: '+7 (###) ###-##-##',
      name: '',
      phone: '',
      newTel: '',
      isError: {
        phone: false,
        name: false,
      },
      hover: false,
      isShowModal: false,
      isPending: false,
      isPendingError: false,
      loading: false,
    }
  },
  computed: {
    checkUrl() {
      return window.location.href.includes('capital.orda-invest.kz')
    },
  },
  watch: {
    name: {
      handler() {
        this.isPending = false
        return (this.isError.name = false)
      },
    },
    phone: {
      handler() {
        this.isPending = false
        return (this.isError.phone = false)
      },
    },
  },
  methods: {
    onClickOutside() {
      this.$emit('close')
    },
    formatTel(val) {
      return val.replace(/[()+-\s]/g, '')
    },
    async submitData() {
      this.loading = true
      this.checkError()
      if (!this.isError.name && !this.isError.phone) {
        this.formatTel(this.phone)
        await axios
          .post(
            this.checkUrl
              ? 'https://new.orda-invest.kz/api/v1/requests'
              : 'https://orda-invest-api.rocketfirm.tech/api/v1/requests',
            {
              phone: this.formatTel(this.phone),
              name: this.name,
              placement_id: 1,
              type_id: 1,
            },
          )
          .catch(e => {
            this.isPendingError = true

            setTimeout(() => {
              this.isPendingError = false
            }, 3000)
            return e
          })
        this.phone = ''
        this.name = ''
        setTimeout(() => {
          this.isPending = true
        }, 500)
      }
      this.loading = false
    },
    checkError() {
      if (this.phone.length < 18 && this.name.length < 1) {
        return (this.isError = { phone: true, name: true })
      } else if (this.name.length < 1) {
        return (this.isError.name = true)
      } else if (this.phone.length < 18) {
        return (this.isError.phone = true)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  width: fit-content;
  min-height: 550px;
  z-index: 1050;
  // margin-top: -300px;
  margin-left: -225px;
  animation: appearance 1s;

  @include mobile {
    left: 15px;
    width: calc(100vw - 30px);
    margin: 0;
    top: 10%;
  }

  @keyframes appearance {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__body {
    padding: 62px 44px;
    overflow: hidden;
    border-radius: 40px;
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;

    @include mobile-small {
      margin: 0 auto;
      width: 100%;
      padding: 30px 15px;
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 36px;
    height: 36px;
    background: $gray-700;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 200;

    @include mobile {
      top: 20px;
      right: 30px;
    }

    @include mobile-small {
      top: 10px;
      right: 15px;
      width: 28px;
      height: 28px;
    }
  }
  &-content {
    max-width: 340px;
    gap: 20px;
    @include flex-column;

    @include mobile-small {
      gap: 15px;
      max-width: auto;
    }

    &__title {
      display: block;
      @include font-styles(26px, 36px, 600, $c-dark);
    }

    &__subtitle {
      display: block;
      @include font-styles(18px, 20px, 400, $c-gray);
    }

    &-bottom {
      &__flex {
        gap: 20px;
        @include flex-column;

        @include mobile-small {
          gap: 15px;
        }
      }
      &__input {
        gap: 4px;
        @include flex-column;

        > span {
          @include font-styles(12px, 14px, 400, $c-gray, 0, $font-second);
        }

        > input {
          padding: 11.5px 12px;
          @include font-styles(16px, 24px, 400, $c-dark);
        }

        .label-error {
          @include font-styles(12px, 14px, 400, red);

          @include mobile {
            @include font-styles(10px, 12px, 400, red);
          }
        }
      }
    }

    &-middle__text--green {
      text-align: center;
      @include font-styles(22px, 30px, 600, $c-main-green);

      @include mobile {
        text-align: center;
        @include font-styles(20px, 26px, 600, $c-main-green);
      }
    }
    &-middle__text--red {
      text-align: center;
      @include font-styles(22px, 30px, 600, red);

      @include mobile {
        @include font-styles(20px, 26px, 600, red);
      }
    }
  }
}
</style>
